import React from "react";

const Header2 = ({ children, className }) => (
    <h2
        className={`${className} text-2xl md:text-3xl xl:text-4xl font-normal font-cherry-bomb text-outragous-orange leading-10`}
    >
        {children}
    </h2>
);

export default Header2;
